import { extendTheme } from "@chakra-ui/react"

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
}

const theme = {
  fonts: {
    heading: "Montserrat",
    body: "Montserrat",
  },
  config,
  components: {},
  /* styles: {
    global: {
      th: {
      },
    },
  }, */
}

export default extendTheme(theme)
